<template>
  <section class="px-0">
    <v-data-table
      :headers="tituloCampos"
      :items="detalles"
      class="elevation-1"
      item-value="id"
      return-object
      hide-default-footer
      no-data-text="No hay datos que mostrar"
    >
      <template v-slot:[`item.cantidad`]="{ item }">
        <v-text-field type="number" v-model="item.cantidad"> </v-text-field>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="eliminar(item.correlativo)"
            >
              <v-icon>mdi-delete </v-icon>
            </v-btn>
          </template>
          <span> Eliminar </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </section>
</template>
<script>
export default {
  name: "DetalleInsumosTableComponent",
  components: {},
  props: {
    obs: {
      type: Object,
      default: () => {},
    },
    detalles: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tituloCampos: [
      {
        text: "Correlativo",
        value: "correlativo",
        align: "center",
        width: "10em",
      },
      {
        text: "Mes",
        value: "mes",
        align: "center",
        width: "40em",
      },
      {
        text: "Cantidad",
        value: "cantidad",
        align: "center",
        width: "10em",
      },
      {
        text: "Precio Unitario ($)",
        value: "precio",
        align: "center",
      },
      {
        text: "Total ($)",
        value: "total",
        align: "center",
      },
      { text: "Acciones", value: "acciones", align: "center" },
    ],
  }),
  computed: {},
  watch: {},
  methods: {
    eliminar(id) {
      this.$emit("eliminar", id);
    },
  },
  mounted() {},
  created() {},
};
</script>
