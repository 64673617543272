<template>
  <div>
    <v-dialog :max-width="770" v-model="modal">
      <v-card>
        <v-btn icon @click="cerrar()" color="secondary"
          ><v-icon>mdi-window-close</v-icon></v-btn
        >
        <v-row>
          <v-col class="pl-10">
            <p class="text-h6 secondary--text">Solicitar Insumo</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-10 pt-0">
            <DetalleOBSComponent :obs="obs"></DetalleOBSComponent>
          </v-col>
        </v-row>
        <v-card-text class="text-center">
          <v-row>
            <v-col
              cols="12"
              xl="10"
              lg="10"
              md="12"
              sm="12"
              xs="12"
              class="pt-0"
            >
              <v-text-field
                label="Unidad de medida"
                outlined
                :disabled="true"
                :value="unidad ? unidad.nombre : null"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              xl="10"
              lg="10"
              md="12"
              sm="12"
              xs="12"
              class="pt-0"
            >
              <v-text-field
                label="Cantidad"
                outlined
                :maxlength="3"
                type="number"
                v-model="form.cantidad"
                :error-messages="cantidadErrors"
                @blur="$v.form.cantidad.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="px-7 py-0 my-0">
              <p class="text-lg-left text-subtitle-1">Meses</p>
            </v-col>
            <v-checkbox
              cols="6"
              :on-icon="''"
              :off-icon="''"
              v-for="item in meses"
              :key="item.id"
              :value="item.id"
              v-model="form.meses"
              hide-details
              color="bgMinsal"
            >
              <template v-slot:label>
                <v-alert
                  :outlined="form.meses.includes(item.id) ? true : false"
                  :color="
                    form.meses.includes(item.id) ? 'secondary' : 'bgMinsal'
                  "
                  :class="
                    form.meses.includes(item.id) ? 'checked' : 'unchecked'
                  "
                >
                  {{ item.name }}
                </v-alert>
              </template>
            </v-checkbox>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <section>
            <div class="d-flex justify-center pa-7">
              <v-btn text color="secondary" @click="cerrar()" outlined
                >Cancelar</v-btn
              >
              <v-col cols="1"></v-col>
              <v-btn color="secondary" @click="agregar()">Agregar</v-btn>
            </div>
          </section>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required, maxLength, helpers } from "vuelidate/lib/validators";
import DetalleOBSComponent from "./DetalleOBSComponent.vue";
const alpha = helpers.regex("alpha", /^(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1\u002d]*)+$/);
const alphaNum = helpers.regex(
  "alphaNum",
  /^(\s*[a-zA-ZÀ-ÿ0-9\u00f1\u00d1]*)+$/
);

export default {
  name: "ModalInsumoCantidadComponent",
  components: { DetalleOBSComponent },
  props: {
    modal: {
      type: Boolean,
    },
    obs: {
      type: Object,
    },
    unidad: {
      type: Object,
    },
    precio: {
      type: Number,
    },
  },
  validations: {
    form: {
      cantidad: {
        required,
        maxLength: maxLength(3),
      },
      meses: {
        required,
      },
    },
  },
  data: () => ({
    form: {
      id: null,
      cantidad: null,
      meses: [],
    },
    meses: [
      { id: 1, name: "Enero" },
      { id: 2, name: "Febrero" },
      { id: 3, name: "Marzo" },
      { id: 4, name: "Abril" },
      { id: 5, name: "Mayo" },
      { id: 6, name: "Junio" },
      { id: 7, name: "Julio" },
      { id: 8, name: "Agosto" },
      { id: 9, name: "Septiembre" },
      { id: 10, name: "Octubre" },
      { id: 11, name: "Noviembre" },
      { id: 12, name: "Diciembre" },
    ],
    detalles: [],
    count: 1,
  }),
  computed: {
    cantidadErrors() {
      const errors = [];
      if (!this.$v.form.cantidad.$dirty) return errors;
      !this.$v.form.cantidad.required && errors.push("Cantidad es obligatorio");
      !this.$v.form.cantidad.maxLength &&
        errors.push("Longuitud maxima de 3 caracteres");
      return errors;
    },
    mesesErrors() {
      const errors = [];
      if (!this.$v.form.meses.$dirty) return errors;
      !this.$v.form.meses.required && errors.push("Meses es obligatorio");
      return errors;
    },
  },
  methods: {
    agregarDetallesInsumo() {
      const detalles = this.form.meses.map((item) => {
        const { name } = this.meses.find((element) => item === element.id);
        const detalle = {
          correlativo: this.count++,
          mes: name,
          cantidad: this.form.cantidad,
          precio: this.precio,
          total: this.form.cantidad * this.precio,
        };
        return detalle;
      });

      return detalles;
    },
    agregar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.detalles = this.agregarDetallesInsumo();
          this.$emit("agregarInsumosMes", this.detalles);
          this.cerrar();
        } catch (error) {}
      } else {
        this.temporalAlert({
          show: true,
          message: "Hay campos obligatorios vacíos",
          type: "warning",
        });
      }
    },
    cerrar() {
      this.$emit("cerrarModal");
      this.formClean();
    },
    formClean() {
      this.form.id = null;
      this.form.cantidad = null;
      this.form.meses = [];
      this.count = 1;
      this.$v.$reset();
    },
  },
};
</script>

<style></style>
