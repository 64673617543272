<template>
  <div v-if="obs" class="pt-0 pb-10">
    <p class="text-h6 ma-0">{{ obs.codigo + " " + obs.nombre }}</p>
    <p class="text-h7 ma-0">Clase : {{ obs.codigo + " " + obs.clase }}</p>
    <p class="text-h7 ma-0">
      Mercancia : {{ obs.codigo + " " + obs.mercancia }}
    </p>
  </div>
</template>
<script>
export default {
  name: "DetalleOBSComponent",
  components: {},
  props: {
    obs: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({}),
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  created() {},
};
</script>
