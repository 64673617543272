<template>
  <div>
    <p class="text-h6 secondary--text ma-0">
      Solicitud de compra por compra en línea
    </p>
    <p class="text-h7 ma-0">Unidad de Retención de Talento Humano</p>
    <p class="text-h7 ma-0">Ejercicio 2022</p>
    <v-row class="pt-7">
      <v-col cols="12" xl="10" lg="10" md="12" sm="12" xs="12">
        <v-text-field
          label="Enlace del artículo"
          outlined
          v-model="form.enlace"
          :error-messages="enlaceErrors"
          @blur="$v.form.enlace.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="10" sm="12" xs="12" class="pt-0">
        <v-autocomplete
          v-model="form.proveedor"
          :items="proveedores"
          item-text="nombre"
          item-value="id"
          outlined
          label="Proveedor"
          :error-messages="proveedorErrors"
          @blur="$v.form.proveedor.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="4" lg="4" md="9" sm="12" xs="12" class="pt-0">
        <v-autocomplete
          v-model="form.unidad"
          :items="unidades"
          item-text="nombre"
          item-value="id"
          outlined
          return-object
          label="Unidad de medida"
          :error-messages="unidadErrors"
          @blur="$v.form.unidad.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="2" lg="2" md="8" sm="12" xs="12" class="pt-0">
        <v-text-field
          label="Precio unitario"
          outlined
          type="number"
          prefix="$"
          v-model="form.precio"
          :error-messages="precioErrors"
          @blur="$v.form.precio.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <p class="text-h7 ma-4">Detalle de insumo</p>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-btn color="primary" @click="agregar()"> Agregar </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="10" lg="10" md="12" sm="12" xs="12" class="pt-0">
        <DetalleInsumosTableComponent
          :detalles="detalles"
          @eliminar="eliminarDetalle"
        ></DetalleInsumosTableComponent>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="10" lg="10" md="12" sm="12" xs="12" class="pt-7">
        <v-textarea
          name="text-area"
          label="Aclaraciones y observaciones"
          v-model="form.observaciones"
          outlined
          :maxlength="250"
        >
        </v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <router-link
          :to="{ name: 'verificar-proveedor-compra' }"
          v-if="action == '2'"
        >
          <v-btn text block> Volver </v-btn>
        </router-link>
        <v-btn v-else text block @click="back()"> Volver </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <router-link
          v-if="action != '2'"
          :to="{ name: 'crear-solicitud-compra', replace: true }"
        >
          <v-btn block color="primary"> Agregar Insumo </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <!-- MODAL AGREGAR -->
    <ModalInsumoCantidadComponent
      :modal="modalAgregar"
      :obs="obs"
      :unidad="form.unidad"
      :precio="Number(form.precio)"
      @agregarInsumosMes="agregarInsumosMes"
      @cerrarModal="cerrarModal()"
    />
  </div>
</template>

<script>
import { required, helpers } from "vuelidate/lib/validators";
import DetalleInsumosTableComponent from "./DetalleInsumosTableComponent.vue";
import ModalInsumoCantidadComponent from "./ModalInsumoCantidadComponent.vue";

// Validación personalizada para sitios web
const url = helpers.regex(
  "url",
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
);

export default {
  name: "AgregarInsumoStep2Component",
  components: { DetalleInsumosTableComponent, ModalInsumoCantidadComponent },
  props: {
    obs: {
      type: Object,
    },
    action: {
      type: String,
    },
  },
  validations: {
    form: {
      enlace: {
        required,
        url,
      },
      proveedor: {
        required,
      },
      unidad: {
        required,
      },
      precio: {
        required,
      },
    },
  },
  data: () => ({
    form: {
      enlace: null,
      proveedor: null,
      unidad: null,
      precio: null,
      observaciones: null,
    },
    modalAgregar: false,
    modalEliminar: false,
    proveedores: [{ id: 1, nombre: "Amazon US" }],
    objetos: [
      {
        id: 1,
        codigo: "14111500",
        nombre: "Papel de imprenta y papel de escribir",
        clase: "Papel de imprenta y de escribir",
        mercancia: "Papel de escritura",
      },
      {
        id: 2,
        codigo: "12541770",
        nombre: "Cinta adhesiva y pegamento",
        clase: "Cinta adhesiva y pegamento clase B",
        mercancia: "Pegamento para papel",
      },
    ],
    unidades: [
      {
        id: 1,
        nombre: "Libra",
      },
      {
        id: 2,
        nombre: "Resma",
      },
    ],
    detalles: [],
  }),
  computed: {
    enlaceErrors() {
      const errors = [];
      if (!this.$v.form.enlace.$dirty) return errors;
      !this.$v.form.enlace.required &&
        errors.push("Enlace del articulo es obligatorio");
      !this.$v.form.enlace.url && errors.push("El sitio web es inválido");
      return errors;
    },
    proveedorErrors() {
      const errors = [];
      if (!this.$v.form.proveedor.$dirty) return errors;
      !this.$v.form.proveedor.required &&
        errors.push("Proveedor es obligatorio");
      return errors;
    },
    unidadErrors() {
      const errors = [];
      if (!this.$v.form.unidad.$dirty) return errors;
      !this.$v.form.unidad.required && errors.push("Unidad es obligatorio");
      return errors;
    },
    precioErrors() {
      const errors = [];
      if (!this.$v.form.precio.$dirty) return errors;
      !this.$v.form.precio.required && errors.push("Precio es obligatorio");
      return errors;
    },
  },
  watch: {},
  methods: {
    next() {
      // this.$v.form.$touch();
      //   if (!this.$v.form.$error) {
      if (true) {
        this.$emit("next");
      } else {
        this.temporalAlert({
          show: true,
          message: "Hay campos obligatorios vacíos",
          type: "warning",
        });
      }
    },
    back() {
      this.$emit("back");
    },
    agregar() {
      this.modalAgregar = true;
    },
    cerrarModal() {
      this.modalAgregar = false;
    },
    agregarInsumosMes(detalles) {
      this.detalles = [...this.detalles, ...detalles];
    },
    eliminarDetalle(id) {
      this.detalles = this.detalles.filter((item) => item.correlativo !== id);
    },
  },
  mounted() {},
  created() {},
};
</script>
